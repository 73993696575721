<template>
  <div
    v-if="categories.length > 0"
    class="category-slider-wrapper"
    :class="$route.path.includes('template') ? 'stick-header' : ''">
    <div class="category-slider">
      <slick ref="slick" :options="slickOptions" @afterChange="handleAfterChange" @init="handleInit">
        <div
          v-for="(category, index) in categories"
          :key="index"
          class="category-item unselect"
          :class="{ active: selectedCategory === category.id }"
          @mouseover="setHovered(category, true)"
          @mouseleave="setHovered(category, false)"
          @click="selectCategory(category)">
          <img :src="getImageSrc(category)" :alt="category.name" class="category-icon" />
          <div class="category-name">{{ category.name }}</div>
        </div>
      </slick>
      <template v-if="!isFirstPage">
        <div class="shadow-box-left"></div>
        <button @click="prevSlide" class="slick-arrow slick-prev">
          <img src="/static/icon/u_angle-left-b.svg" alt="arrow-left" />
        </button>
      </template>
      <template v-if="!isLastPage">
        <div class="shadow-box-right"></div>
        <button @click="nextSlide" class="slick-arrow slick-next">
          <img src="/static/icon/u_angle-right-b.svg" alt="arrow-right" />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';

export default {
  name: 'CategorySlider',
  components: { Slick },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    seleted: {
      type: Object,
    },
  },
  data() {
    return {
      isFirstPage: true,
      isLastPage: false,
      slickOptions: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 9,
        arrows: false,
        draggable: true,
        swipe: true,
        touchMove: true,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 7,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ],
      },
      currentImages: {},
      selectedCategory: this.$route.path.includes('/template_search') ? 0 : null,
    };
  },
  methods: {
    prevSlide() {
      this.$refs.slick.prev();
    },
    nextSlide() {
      this.$refs.slick.next();
    },
    setHovered(item, isHovered) {
      if (this.selectedCategory !== item.id) {
        if (isHovered) {
          this.$set(this.currentImages, item.id, item.img_after);
        } else {
          this.$set(this.currentImages, item.id, item.img_before);
        }
      }
    },
    getImageSrc(item) {
      return this.selectedCategory === item.id ? item.img_after : this.currentImages[item.id] || item.img_before;
    },
    selectCategory(category) {
      if (this.selectedCategory === category.id) {
        return;
      }

      this.selectedCategory = category.id;
      this.$set(this.currentImages, category.id, category.img_after);
      this.$emit('selectCategory', category);
    },
    handleAfterChange(event, slick, currentSlide) {
      this.updatePageState(currentSlide, slick);
    },
    handleInit(event, slick) {
      this.updatePageState(slick.currentSlide, slick);
    },
    updatePageState(currentSlide, slick) {
      const slideCount = this.categories.length;
      const slidesToShow = slick.options.slidesToShow;

      this.isFirstPage = currentSlide === 0;
      this.isLastPage = currentSlide + slidesToShow >= slideCount;

      // console.log('Current Slide:', currentSlide);
      // console.log('Is First Page:', this.isFirstPage);
      // console.log('Is Last Page:', this.isLastPage);
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    scrollToCategory(categoryId) {
      const slideIndex = this.categories.findIndex((category) => category.id === categoryId);
      console.log('categoryId', categoryId);
      console.log('slideIndex', slideIndex);
      if (slideIndex !== -1) {
        this.$nextTick(() => {
          this.$refs.slick.goTo(slideIndex);
        });
      }
    },
    waitForCategories(categoryId) {
      if (this.categories.length > 0) {
        this.$nextTick(() => {
          this.scrollToCategory(categoryId);
        });
      } else {
        setTimeout(() => this.waitForCategories(categoryId), 100);
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.reInit);
    if (this.seleted) {
      this.selectedCategory = this.seleted.id;
      this.$set(this.currentImages, this.seleted.id, this.seleted.img_after);
      this.waitForCategories(this.seleted.id);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.reInit);
  },
  watch: {
    seleted(newVal) {
      console.log('seleted', newVal);
      if (newVal) {
        this.selectedCategory = newVal.id;
        this.$set(this.currentImages, newVal.id, newVal.img_after);
        this.waitForCategories(newVal.id);
      }
    },
    categories: {
      handler() {
        if (this.selectedCategory) {
          this.scrollToCategory(this.selectedCategory);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.category-slider-wrapper {
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.stick-header {
  position: sticky;
  top: 64px;
  z-index: 2;
}

.category-slider {
  position: relative;
  width: 1152px;
  margin: 0 auto;
  padding: 0 36px;
  box-sizing: border-box;
}

.slick-container {
  overflow: hidden;
}

.category-item {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  width: 100px !important;
  margin: 0 10px !important;
  padding: 16px 8px;
}
.category-item:hover {
  border-bottom: 3px solid #242428;
  padding: 16px 8px 13px 8px;
}

.category-item.active {
  border-bottom: 3px solid #242428;
  padding: 16px 8px 13px 8px;
}

.category-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.slick-slide img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  padding: 0px !important;
}

.category-name {
  margin-top: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shadow-box-right {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 100px !important;
  height: 84px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 57.13%);
}
.shadow-box-left {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 100px !important;
  height: 84px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 57.13%);
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  font-size: 0;
  cursor: pointer;
  z-index: 1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev {
  left: 0;
}
.slick-prev:before {
  content: '';
}

.slick-next {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 57.13%);
}
.slick-next:before {
  content: '';
}

.arrow-icon {
  font-size: 18px;
  color: #333;
  line-height: 1;
}

.slick-slider {
  width: 100%;
}

.slick-list {
  overflow: hidden;
}

.slick-track {
  display: flex;
  flex-wrap: nowrap;
}

.slick-slide {
  float: none !important;
  height: auto;
}
</style>
